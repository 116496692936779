import React from 'react'
import './NotFoundPage.css'

export default class NotFoundPage extends React.Component{
  render(){
    return (
      <div>
        <section className="not-found">
        <h2 className="not-found-header">Sorry! Page Not Found.</h2>
          </section>
      </div>
    )
  }
}